import React, { useEffect } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { Form, Formik } from "formik";
import { Form as BootstrapForm } from "react-bootstrap";
import * as Yup from "yup";
import FormControl from "../components/FormControl";
import axios from "axios";

const Youtube = () => {
  useEffect(() => {
    document.title = "Youtube Deep Link";
  }, []);

  const initialValues = {
    urlkey: "",
    sourceid: "",
    type: "youtube",
  };

  const validationSchema = Yup.object({
    urlkey: Yup.string().required("Please Enter URL Key"),
    sourceid: Yup.string().required("Please Enter Youtube Link"),
  });

  const onSubmit = async (values, helpers) => {
    values.sourceid = values.sourceid.replace("https://www.youtube.com/watch?v=", "");
    axios
      .post(
        "https://socialshares.xyz/deeplink",
        JSON.stringify(values),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        alert(
          "Successfully Created Deep Link. Please Tell Owner to Activate Deep Link : https://socialshares.xyz/" +
            values.urlkey + " Contact here : enquiry@socialshares.xyz"
        );
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  return (
    <Container>
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <Card className="mt-5">
            <Card.Header>Youtube Deep Link (enquiry@socialshares.xyz)</Card.Header>
            <Card.Body>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, isValid, dirty, setFieldValue }) => (
                  <Form>
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="urlkey">
                          <FormControl
                            type="text"
                            name="urlkey"
                            placeholder="Url Key"
                            tabIndex={1}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <BootstrapForm.Group controlId="sourceid">
                          <FormControl
                            type="text"
                            name="sourceid"
                            placeholder="Youtube URL (https://www.youtube.com/watch?v=5RZws-BEl4s)"
                            tabIndex={2}
                          />
                        </BootstrapForm.Group>
                      </Col>
                    </Row>
                    <br />
                    <Button
                      type="submit"
                      tabIndex={3}
                      block
                      disabled={!(dirty && isValid) || isSubmitting}
                    >
                      {isSubmitting ? <Spinner animation="border" size="sm" /> : "Submit"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Youtube;
